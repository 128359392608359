import { Link, useStaticQuery, graphql } from "gatsby";
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../../components/layout";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import ServiceHero from "../../components/service-hero";
import Badges from "../../components/badges";
import BumperBanner from "../../components/bumber-banner";
import OurServicesSection from "../../components/our-services-section";
import Reviews from "../../components/reviews";
import MotForm from "../../components/mot-form";
import Faq from "../../components/faq-accordian";
import { checkPropertiesForNull } from "../../../utils";
import { SafeHtmlParser } from "../../components/safeHtmlParser";

const MOTPage = () => {
  const data = useStaticQuery(graphql`
    query {
      reviews {
        reviewCount
        reviewAuthor
        reviewDate
        reviewContent
      }
      schemaLogo: file(relativePath: { eq: "icon.png" }) {
        publicURL
      }

      pageData: wpPage(slug: { eq: "mot-eastbourne" }) {
        slug
        motEastbourneFields {
          motEastbourneBanner {
            motEastbourneBannerHeading
            motEastbourneBannerBackgroundImage {
              node {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                  }
                }
              }
            }
          }
          motEastbourneBgImageOverlayContent {
            motEastbourneOverlayHeading
            motEastbourneOverlayContent
            motEastbourneBgImage {
              node {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                  }
                }
              }
            }
          }
          motEastbourneBumpers {
            motEastbourneBumpersHeading
            motEastbourneBumpersCta {
              title
              target
              url
            }
            motEastbourneBumpersNumber {
              accidentRepairBumperNumberHeading
              accidentRepairBumperNumberPostfix
              accidentRepairBumperNumberPrefix
              accidentRepairBumperNumberText
            }
          }
          motEastbourneFaqSection {
            motEastbourneFaqSectionHeading
            motEastbourneFaqSectionDescription
            motEastbourneFaqs {
              motEastbourneAnswer
              motEastbourneQuestion
            }
          }
          motEastbourneFormWithContent {
            motEastbourneContentHeading
            motEastbourneContentText
          }
          motEastbourneLeftContentRightImage {
            motEastbourneLeftHeading
            motEastbourneLeftContent
            motEastbourneRightImage {
              node {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                  }
                }
              }
            }
          }
          motEastbourneLeftContentRightImageRepeat {
            motEastbourneLeftHeadingRepeat
            motEastbourneLeftContentRepeat
            motEastbourneRightImageRepeat {
              node {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                  }
                }
              }
            }
          }
          motEastbourneLeftImageRightContent {
            motEastbourneRightContentHeading
            motEastbourneRightContentText
            motEastbourneLeftImage {
              node {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                  }
                }
              }
            }
          }
          motEastbourneLogoSlider {
            motEastbourneLogoSliderHeading
            motEastbourneLogoSliderNotice
            motEastbourneLogos {
              nodes {
                ... on WpVehicleLogo {
                  vehicleLogoFields {
                    logoUrl
                    logoImage {
                      node {
                        altText
                        localFile {
                          publicURL
                          childImageSharp {
                            gatsbyImageData(
                              formats: [AUTO, WEBP]
                              quality: 100
                              transformOptions: {
                                cropFocus: CENTER
                                fit: CONTAIN
                              }
                              layout: CONSTRAINED
                              placeholder: BLURRED
                            )
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          motEastbourneReviews {
            motEastbourneReviewsHeading
          }
          motEastbourneServicesCards {
            motEastbourneServicesCardsHeading
            motEastbourneServicesCards {
              nodes {
                ... on WpService {
                  title
                  servicesPostFields {
                    serviceUrl {
                      target
                      title
                      url
                    }
                  }
                  featuredImage {
                    node {
                      altText
                      localFile {
                        publicURL
                        childImageSharp {
                          gatsbyImageData(
                            formats: [AUTO, WEBP]
                            quality: 100
                            transformOptions: {
                              cropFocus: CENTER
                              fit: CONTAIN
                            }
                            layout: CONSTRAINED
                            placeholder: BLURRED
                          )
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        seoFields {
          title
          opengraphTitle
          opengraphDescription
          metaDescription
          localBusinessSchema
          image {
            node {
              altText
              localFile {
                childImageSharp {
                  original {
                    height
                    width
                  }
                }
                publicURL
              }
            }
          }
        }
      }
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `);

  const {
    pageData: { seoFields, motEastbourneFields, slug },
  } = data;

  const {
    motEastbourneBanner,
    motEastbourneFormWithContent,
    motEastbourneBgImageOverlayContent,
    motEastbourneLeftContentRightImage,
    motEastbourneLeftImageRightContent,
    motEastbourneLeftContentRightImageRepeat,
    motEastbourneReviews,
    motEastbourneLogoSlider,
    motEastbourneBumpers,
    motEastbourneServicesCards,
    motEastbourneFaqSection,
  } = motEastbourneFields;

  const siteUrl = data.site.siteMetadata.siteUrl;
  const serviceSchema = {
    "@context": "https://schema.org/",
    "@type": "Product",
    "@id": `https://www.ecfcarcare.co.uk/what-we-do/${slug}`,
    image: `https://www.ecfcarcare.co.uk${seoFields?.image?.node?.localFile.publicURL}`,
    mpn: `https://www.ecfcarcare.co.uk/what-we-do/${slug}`,
    sku: `https://www.ecfcarcare.co.uk/what-we-do/${slug}`,
    description: `${seoFields?.metaDescription}`,
    logo: `https://www.ecfcarcare.co.uk${data.schemaLogo.publicURL}`,
    name: "ECF Car Care",
    url: "https://www.ecfcarcare.co.uk",
    brand: {
      "@type": "Organization",
      logo: `https://www.ecfcarcare.co.uk${data.schemaLogo.publicURL}`,
      image: `https://www.ecfcarcare.co.uk${seoFields?.image?.node?.localFile.publicURL}`,
      name: "ECF Car Care",
      url: "https://www.ecfcarcare.co.uk",
    },
    aggregateRating: {
      "@type": "AggregateRating",
      reviewCount: `${data.reviews.reviewCount}`,
      ratingValue: "5",
      bestRating: "5",
      worstRating: "1",
    },

    review: {
      "@type": "Review",
      url: "https://www.ecfcarcare.co.uk/reviews",
      datePublished: `${data.reviews.reviewDate}`,
      reviewBody: `${data.reviews.reviewContent}`,
      author: {
        "@type": "Person",
        name: `${data.reviews.reviewAuthor}`,
      },
      reviewRating: {
        "@type": "Rating",
        ratingValue: "5",
        bestRating: "5",
        worstRating: "1",
      },
      publisher: {
        "@type": "Organization",
        name: "ECF Car Care",
        sameAs: "https://www.ecfcarcare.co.uk",
      },
    },
  };

  const breadcrumb = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: "1",
        name: "Home",
        item: {
          url: `${siteUrl}`,
          id: `${siteUrl}`,
        },
      },
      {
        "@type": "ListItem",
        position: "2",
        name: "What We Do",
        item: {
          url: `${siteUrl}/what-we-do`,
          id: `${siteUrl}/what-we-do`,
        },
      },
      {
        "@type": "ListItem",
        position: "3",
        name: "MOT Eastbourne",
        item: {
          url: `${siteUrl}/what-we-do/mot-eastbourne`,
          id: `${siteUrl}/what-we-do/mot-eastbourne`,
        },
      },
    ],
  };

  return (
    <>
      <Helmet>
        {" "}
        <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
      </Helmet>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(serviceSchema)}
        </script>
      </Helmet>

      <GatsbySeo
        title={seoFields?.title}
        description={seoFields?.metaDescription}
        language="en"
        openGraph={{
          type: "website",
          url: `${siteUrl}`,
          title: `${seoFields?.opengraphTitle}`,
          description: `${seoFields?.opengraphDescription}`,
          images: [
            {
              url: `${seoFields?.image?.node.localFile.publicURL}`,
              width: `${seoFields?.image?.node.localFile.childImageSharp.original.width}`,
              height: `${seoFields?.image?.node.localFile.childImageSharp.original.height}`,
              alt: `${seoFields?.image?.node.altText}`,
            },
          ],
        }}
      />

      <Layout>
        {motEastbourneBanner && (
          <section>
            <ServiceHero
              title={motEastbourneBanner.motEastbourneBannerHeading}
              backgroundImage={
                motEastbourneBanner.motEastbourneBannerBackgroundImage.node
                  ?.localFile.childImageSharp.gatsbyImageData
              }
              imgAlt={
                motEastbourneBanner.motEastbourneBannerBackgroundImage.node
                  ?.altText
              }
            />
          </section>
        )}
        {motEastbourneFormWithContent &&
          !checkPropertiesForNull(motEastbourneFormWithContent, [
            "motEastbourneContentHeading",
          ]) && (
            <section className="py-5  py-lg-7 position-relative">
              <Container>
                <Row className="g-lg-5 g-4 align-items-center">
                  <Col lg={6} className="position-relative">
                    <h2 className=" pb-5">
                      {motEastbourneFormWithContent.motEastbourneContentHeading}
                    </h2>
                    <SafeHtmlParser
                      htmlContent={
                        motEastbourneFormWithContent?.motEastbourneContentText
                      }
                    />
                  </Col>
                  <Col lg={6}>
                    <div
                      style={{ background: "#F9F9F9", borderRadius: "18px" }}
                      className="p-md-5 p-4"
                    >
                      <MotForm page="MOT Eastbourne" />
                    </div>
                  </Col>
                </Row>
              </Container>
            </section>
          )}
        {motEastbourneBgImageOverlayContent &&
          !checkPropertiesForNull(motEastbourneBgImageOverlayContent, [
            "motEastbourneOverlayHeading",
          ]) && (
            <section>
              {" "}
              <BgImage
                style={{ backgroundPosition: "bottom" }}
                alt={
                  motEastbourneBgImageOverlayContent.motEastbourneBgImage.node
                    ?.altText
                }
                image={getImage(
                  motEastbourneBgImageOverlayContent.motEastbourneBgImage.node
                    ?.localFile.childImageSharp.gatsbyImageData
                )}
              >
                <Container
                  style={{ zIndex: 2 }}
                  className="position-relative py-xl-10 py-5 "
                >
                  <Row className="justify-content-start">
                    <Col xl={6} lg={7} className="">
                      <div className="position-relative  p-5">
                        <div
                          style={{
                            zIndex: 1,
                            opacity: 0.9,
                            backgroundColor: "white",
                          }}
                          className="position-absolute h-100 w-100 start-0 bottom-0"
                        ></div>
                        <div
                          className="position-relative"
                          style={{ zIndex: 2 }}
                        >
                          <h2 className=" pb-4">
                            {
                              motEastbourneBgImageOverlayContent.motEastbourneOverlayHeading
                            }
                          </h2>

                          <SafeHtmlParser
                            htmlContent={
                              motEastbourneBgImageOverlayContent?.motEastbourneOverlayContent
                            }
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </BgImage>
            </section>
          )}

        {motEastbourneLeftContentRightImage &&
          !checkPropertiesForNull(motEastbourneLeftContentRightImage, [
            "motEastbourneLeftHeading",
          ]) && (
            <section className="pt-5 pt-lg-7">
              <Container>
                <Row className="g-5">
                  <Col lg={6}>
                    <h2 className=" pb-4">
                      {
                        motEastbourneLeftContentRightImage.motEastbourneLeftHeading
                      }
                    </h2>
                    <GatsbyImage
                      className="d-lg-none mb-5 mb-lg-0"
                      image={
                        motEastbourneLeftContentRightImage
                          .motEastbourneRightImage.node?.localFile
                          .childImageSharp.gatsbyImageData
                      }
                      alt={
                        motEastbourneLeftContentRightImage
                          .motEastbourneRightImage.node?.altText
                      }
                    />
                    <SafeHtmlParser
                      htmlContent={
                        motEastbourneLeftContentRightImage?.motEastbourneLeftContent
                      }
                    />
                  </Col>
                  <Col className="d-none d-lg-block" lg={6}>
                    <BgImage
                      style={{ backgroundPosition: "bottom" }}
                      className="w-100 h-100"
                      image={
                        motEastbourneLeftContentRightImage
                          .motEastbourneRightImage.node?.localFile
                          .childImageSharp.gatsbyImageData
                      }
                      alt={
                        motEastbourneLeftContentRightImage
                          .motEastbourneRightImage.node?.altText
                      }
                    ></BgImage>
                  </Col>
                </Row>
              </Container>
            </section>
          )}
        <section className="py-5 py-lg-7">
          <Container>
            {motEastbourneLeftImageRightContent &&
              !checkPropertiesForNull(motEastbourneLeftImageRightContent, [
                "motEastbourneRightContentHeading",
              ]) && (
                <Row className="g-5">
                  <Col className="d-none d-lg-block" lg={6}>
                    <BgImage
                      style={{ backgroundPosition: "bottom" }}
                      className="w-100 h-100"
                      alt={
                        motEastbourneLeftImageRightContent
                          .motEastbourneLeftImage.node?.altText
                      }
                      image={getImage(
                        motEastbourneLeftImageRightContent
                          .motEastbourneLeftImage.node?.localFile
                          .childImageSharp.gatsbyImageData
                      )}
                    ></BgImage>
                  </Col>
                  <Col lg={6}>
                    <h2 className=" pb-4">
                      {
                        motEastbourneLeftImageRightContent?.motEastbourneRightContentHeading
                      }
                    </h2>
                    <GatsbyImage
                      className="d-lg-none mb-5 mb-lg-0"
                      image={
                        motEastbourneLeftImageRightContent
                          .motEastbourneLeftImage.node?.localFile
                          .childImageSharp.gatsbyImageData
                      }
                      alt={
                        motEastbourneLeftImageRightContent
                          .motEastbourneLeftImage.node?.altText
                      }
                    />
                    <SafeHtmlParser
                      htmlContent={
                        motEastbourneLeftImageRightContent?.motEastbourneRightContentText
                      }
                    />
                  </Col>
                </Row>
              )}
            {motEastbourneLeftContentRightImageRepeat &&
              !checkPropertiesForNull(
                motEastbourneLeftContentRightImageRepeat,
                ["motEastbourneLeftContentRepeat"]
              ) && (
                <Row className="g-5 pt-4">
                  <Col className="d-lg-none" lg={6}>
                    {" "}
                    <GatsbyImage
                      alt={
                        motEastbourneLeftContentRightImageRepeat
                          .motEastbourneRightImageRepeat.node?.altText
                      }
                      image={
                        motEastbourneLeftContentRightImageRepeat
                          .motEastbourneRightImageRepeat.node?.localFile
                          .childImageSharp.gatsbyImageData
                      }
                    />
                  </Col>
                  <Col lg={6}>
                    <SafeHtmlParser
                      htmlContent={
                        motEastbourneLeftContentRightImageRepeat?.motEastbourneLeftContentRepeat
                      }
                    />
                  </Col>
                  <Col className="d-none d-lg-block" lg={6}>
                    <BgImage
                      style={{ backgroundPosition: "bottom" }}
                      className="w-100 h-100 "
                      alt={
                        motEastbourneLeftContentRightImageRepeat
                          .motEastbourneRightImageRepeat.node?.altText
                      }
                      image={
                        motEastbourneLeftContentRightImageRepeat
                          .motEastbourneRightImageRepeat.node?.localFile
                          .childImageSharp.gatsbyImageData
                      }
                    ></BgImage>
                  </Col>
                </Row>
              )}
          </Container>
        </section>
        <section>
          <Reviews title={motEastbourneReviews?.accidentRepairReviewsHeading} />
        </section>
        {motEastbourneLogoSlider &&
          !checkPropertiesForNull(motEastbourneLogoSlider, [
            "motEastbourneLogoSliderHeading",
            "motEastbourneLogos",
          ]) && (
            <Badges
              title={motEastbourneLogoSlider.motEastbourneLogoSliderHeading}
              text={motEastbourneLogoSlider.motEastbourneLogoSliderNotice}
              logos={motEastbourneLogoSlider.motEastbourneLogos?.nodes}
            />
          )}

        {motEastbourneBumpers &&
          !checkPropertiesForNull(motEastbourneBumpers, [
            "motEastbourneBumpersHeading",
            "motEastbourneBumpersNumbers",
          ]) && (
            <BumperBanner
              title={motEastbourneBumpers.motEastbourneBumpersHeading}
              items={motEastbourneBumpers.motEastbourneBumpersNumber}
              ctaButton={motEastbourneBumpers.motEastbourneBumpersCta}
            />
          )}
        {motEastbourneServicesCards &&
          !checkPropertiesForNull(motEastbourneServicesCards, [
            "motEastbourneServicesCardsHeading",
            "motEastbourneServicesCards",
          ]) && (
            <OurServicesSection
              title={
                motEastbourneServicesCards.motEastbourneServicesCardsHeading
              }
              services={
                motEastbourneServicesCards.motEastbourneServicesCards?.nodes
              }
            />
          )}
        {motEastbourneFaqSection &&
          !checkPropertiesForNull(motEastbourneFaqSection, [
            "motEastbourneFaqSectionHeading",
            "motEastbourneFaqs",
          ]) && (
            <section className="py-5 py-lg-8 bg-background">
              <Container className="">
                <Row className="justify-content-center">
                  <Col lg={10} className="text-center pb-6">
                    <h2 className="text-white pb-4">
                      {motEastbourneFaqSection.motEastbourneFaqSectionHeading}
                    </h2>

                    <SafeHtmlParser
                      htmlContent={
                        motEastbourneFaqSection?.motEastbourneFaqSectionDescription
                      }
                    />
                  </Col>
                </Row>
                {motEastbourneFaqSection.motEastbourneFaqs &&
                  motEastbourneFaqSection.motEastbourneFaqs.length > 0 && (
                    <Row>
                      {motEastbourneFaqSection.motEastbourneFaqs.map(
                        (faqItem) => (
                          <Faq
                            question={faqItem.motEastbourneQuestion}
                            answer={
                              <div className="text-white">
                                <SafeHtmlParser
                                  htmlContent={faqItem?.motEastbourneAnswer}
                                />
                              </div>
                            }
                          />
                        )
                      )}
                    </Row>
                  )}
              </Container>
            </section>
          )}
      </Layout>
    </>
  );
};

export default MOTPage;
